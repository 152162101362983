import React, { useEffect, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import { chartColors } from '@/constants/BrandColors';
import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { SubscriptionYoutubeDataFormatter } from '@/formatters/SubscriptionDataFormatter';
import useSubscription from '@/hooks/account/useSubscription';
import useYoutubeAudienceMetrics from '@/hooks/metrics/useStaticYoutubeAudienceMetrics';
import useYoutubeViewLocationsMetrics from '@/hooks/metrics/useYoutubeViewLocations';
import { SubscriptionMetricsMock } from '@/mocks/SubscriptionMocks';
import {
  AudienceMetricsGendersPerAgeModel,
  ChartDataBarModel,
  ChartDataPieModel,
  InsightsDataModel,
  YoutubeAudienceMetricsModel,
  YoutubeMarketCoverageCitiesMetrics,
  YoutubeMarketCoverageCountriesMetrics,
} from '@/models/Metrics';

import BarChartComponent from '../../../../charts/BarChart';
import PieChartComponent from '../../../../charts/PieChart';
import Subscription from '../../../../subscription/Subscription';
import Loading from '../../../../utility/Loading';
import ProgressBar from '../../../../utility/ProgressBar';
import BenchmarkInsightGauge from '../../../BenchmarkInsights/BenchmarkInsightGauge';
import ViewAllModal from '../../../ViewAllModal';
import SubscribersChart from './SubscribersChart';
import ViewsChart from './ViewsChart';

const YoutubeInsights = () => {
  const { t } = useTranslation();

  const { isSubscribed } = useSubscription();

  const [audienceMetrics, setAudienceMetrics] = useState<YoutubeAudienceMetricsModel>();
  const [ageChartData, setAgeChartData] = useState<ChartDataBarModel<AudienceMetricsGendersPerAgeModel>>();
  const [genderChartData, setGenderChartData] = useState<ChartDataPieModel[]>();
  const [topCountries, setTopCountries] = useState<YoutubeMarketCoverageCountriesMetrics[]>();
  const [topCities, setTopCities] = useState<YoutubeMarketCoverageCitiesMetrics[]>();

  const [chosenInsight, setChosenInsight] = useState<InsightsDataModel>();

  const [locationsLoading, setLocationsLoading] = useState<boolean>(true);
  const [notableFollowersLoading, setNotableFollowersLoading] = useState<boolean>(true);

  const { youtubeAudienceMetrics, youtubeAudienceMetricsError } = useYoutubeAudienceMetrics();

  useEffect(() => {
    if (youtubeAudienceMetricsError) {
      setAudienceMetrics(undefined);
      setAgeChartData(undefined);
      setGenderChartData(undefined);
      setTopCountries(undefined);
      setTopCities(undefined);
      setLocationsLoading(false);
      setNotableFollowersLoading(false);
    }
  }, [youtubeAudienceMetricsError]);

  useEffect(() => {
    if (!youtubeAudienceMetrics) return;

    if (isSubscribed) {
      setAudienceMetrics(youtubeAudienceMetrics);
      const codes = youtubeAudienceMetrics.gendersPerAgeGroup.map((item) => item.code);

      const metricsMaps = {
        male: new Map(youtubeAudienceMetrics.gendersPerAgeGroup.map((item) => [item.code, item.male])),
        female: new Map(youtubeAudienceMetrics.gendersPerAgeGroup.map((item) => [item.code, item.female])),
      };

      setAgeChartData({
        name: ['Male', 'Female'],
        key: ['male', 'female'],
        color: [chartColors.barChart.blueOne, chartColors.pieChart.pink],
        data: codes.map((code) => ({
          code,
          male: metricsMaps.male.get(code) || 0,
          female: metricsMaps.female.get(code) || 0,
        })),
      });
      setGenderChartData([
        {
          name: 'Male',
          value: youtubeAudienceMetrics.genders.find((item) => item.code === 'male')?.weight ?? 0,
          fill: chartColors.pieChart.blue,
        },
        {
          name: 'Female',
          value: youtubeAudienceMetrics.genders.find((item) => item.code === 'female')?.weight ?? 0,

          fill: chartColors.pieChart.pink,
        },
      ]);
    } else {
      setAudienceMetrics(SubscriptionYoutubeDataFormatter(youtubeAudienceMetrics));

      const codes = SubscriptionMetricsMock.gendersPerAgeGroup.map((item) => item.code);

      const metricsMaps = {
        male: new Map(SubscriptionMetricsMock.gendersPerAgeGroup.map((item) => [item.code, item.male])),
        female: new Map(SubscriptionMetricsMock.gendersPerAgeGroup.map((item) => [item.code, item.female])),
      };

      setAgeChartData({
        name: ['Male', 'Female'],
        key: ['male', 'female'],
        color: [chartColors.barChart.blueOne, chartColors.pieChart.pink],
        data: codes.map((code) => ({
          code,
          male: metricsMaps.male.get(code) || 0,
          female: metricsMaps.female.get(code) || 0,
        })),
      });
      setGenderChartData([
        {
          name: 'Male',
          value: SubscriptionMetricsMock.genders.find((item) => item.code === 'male')?.weight ?? 0,
          fill: chartColors.pieChart.blue,
        },
        {
          name: 'Female',
          value: SubscriptionMetricsMock.genders.find((item) => item.code === 'female')?.weight ?? 0,

          fill: chartColors.pieChart.pink,
        },
      ]);
    }

    setNotableFollowersLoading(false);
  }, [isSubscribed, youtubeAudienceMetrics]);

  const { youtubeViewLocationsMetrics } = useYoutubeViewLocationsMetrics();

  useEffect(() => {
    if (!youtubeViewLocationsMetrics) return;

    if (isSubscribed) {
      if (youtubeViewLocationsMetrics.cities.length > 0) {
        setTopCities(youtubeViewLocationsMetrics.cities);
      }
      if (youtubeViewLocationsMetrics.countries.length === 0) {
        setTopCountries(youtubeViewLocationsMetrics.countries);
      }
    } else {
      if (youtubeViewLocationsMetrics.cities.length > 0) {
        setTopCities([youtubeViewLocationsMetrics.cities[0], ...SubscriptionMetricsMock.youtubeTopCities]);
      }
      if (youtubeViewLocationsMetrics.countries.length > 0) {
        setTopCountries([youtubeViewLocationsMetrics.countries[0], ...SubscriptionMetricsMock.youtubeTopCountries]);
      }
    }

    setLocationsLoading(false);
  }, [isSubscribed, youtubeViewLocationsMetrics]);

  const hasLocations = useMemo(() => {
    if (topCities && topCities.length > 0) return true;

    if (topCountries && topCountries.length > 0) return true;

    return false;
  }, [topCities, topCountries]);

  return (
    <>
      <ViewAllModal insights={chosenInsight} onClose={() => setChosenInsight(undefined)} />
      <BenchmarkInsightGauge platform="YouTube" />
      <div className="d-flex gap20 flex-wrap mb20 mt20">
        <SubscribersChart />
        <ViewsChart />
      </div>
      <Subscription type="banner-small" />

      <div className="d-flex gap20 mt20 flex-wrap ">
        <div className="card flex-grow flex-w30p min-w300 d-flex flex-d-col">
          <h4 className="mb20">{t('INSIGHTS-PAGE.COMMON.AGE')}</h4>
          <div className={`${!isSubscribed ? 'subscription-item-blur' : ''} mt-auto mb-auto`}>
            {(!ageChartData || ageChartData.data.length === 0) && <p>No age data</p>}
            {ageChartData && ageChartData.data.length > 0 && (
              <BarChartComponent<AudienceMetricsGendersPerAgeModel>
                data={ageChartData}
                xAxisKeyName="code"
                suffix="%"
              />
            )}
          </div>
        </div>
        <div className="card flex-grow flex-w30p min-w300 d-flex flex-d-col">
          <h4 className="">{t('INSIGHTS-PAGE.COMMON.GENDER')}</h4>
          <div className={`${!isSubscribed ? 'subscription-item-blur' : ''} mt-auto mb-auto`}>
            {!genderChartData && <p className="mt20">No gender data</p>}
            {genderChartData && <PieChartComponent data={genderChartData} suffix="%" />}
          </div>
        </div>
        <div className="flex-grow flex-w30p min-w300">
          <div className="card notable-followers">
            <div className="d-flex jc-space-between mb20">
              <h4 className="flex-grow w100p">{t('INSIGHTS-PAGE.INSTAGRAM.NOTABLE-FOLLOWERS')}</h4>

              {isSubscribed ? (
                <ButtonComponent
                  isCustom
                  className="min-w71"
                  onClick={() => {
                    setChosenInsight({
                      title: 'INSIGHTS-PAGE.INSTAGRAM.NOTABLE-FOLLOWERS',
                      type: 'list-item',
                      data: audienceMetrics?.notableSubscribers,
                    });
                  }}
                >
                  <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
                </ButtonComponent>
              ) : (
                <Subscription
                  type="element"
                  element={<img className="ml-auto" src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
                />
              )}
            </div>
            {notableFollowersLoading && <Loading size="small" />}

            {!notableFollowersLoading &&
              audienceMetrics?.notableSubscribers &&
              audienceMetrics?.notableSubscribers.slice(0, 4).map((item, index) => (
                <div
                  key={index}
                  className={`d-flex list-item ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}
                >
                  <div>
                    <object className="playlist-image" data={item.picture} type="image/png">
                      <img className="playlist-image" src={Images.profilePlaceholder} alt="Fallback playlist img" />
                    </object>
                  </div>
                  <div className="mt-auto mb-auto">
                    <p>{item.fullName}</p>
                    <p className="text-faded">{numberFormatter(item.subscribers)}</p>
                  </div>
                  <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
                </div>
              ))}
            {!notableFollowersLoading &&
              (!audienceMetrics?.notableSubscribers || audienceMetrics?.notableSubscribers.length === 0) && (
                <p>No notable followers</p>
              )}
          </div>
        </div>
      </div>
      {hasLocations && (
        <div className="card mt20">
          <h3 className="mb20">{t('INSIGHTS-PAGE.COMMON.LOCATION')}</h3>
          <div className="d-flex gap20 flex-wrap">
            <div className="card-inner flex-grow flex-w50p w100p-lg-down">
              <div className="d-flex jc-space-between mb20">
                <h4 className="flex-grow w100p">{t('INSIGHTS-PAGE.COMMON.TOP-COUNTRIES')}</h4>

                {isSubscribed ? (
                  <p
                    className="pt4 cursor-pointer min-w71"
                    onClick={() => {
                      setChosenInsight({
                        title: 'INSIGHTS-PAGE.COMMON.TOP-COUNTRIES',
                        type: 'progress-location',
                        data: topCountries,
                      });
                    }}
                  >
                    {t('COMMON.VIEW-ALL')}
                  </p>
                ) : (
                  <Subscription
                    type="element"
                    element={<img className="ml-auto" src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
                  />
                )}
              </div>
              <div>
                {locationsLoading && <Loading size="small" />}

                {!locationsLoading &&
                  topCountries &&
                  topCountries.length > 0 &&
                  topCountries.slice(0, 7).map((item, index) => (
                    <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                      <div className="d-flex jc-space-between min-h30">
                        <p className="small">
                          <span className="">{item?.name}</span>
                        </p>
                        <p className="small text-faded mt-auto mb-auto">{numberFormatter(item?.views, 2)} views</p>
                      </div>
                      <ProgressBar
                        progress={item?.percentageOfTotalViews}
                        tooltip={numberFormatter(item?.percentageOfTotalViews, 2)}
                        title=""
                        label={''}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="card-inner flex-grow flex-w50p w100p-lg-down">
              <div className="d-flex jc-space-between mb20">
                <h4 className="flex-grow w100p">{t('INSIGHTS-PAGE.COMMON.TOP-CITIES')}</h4>

                {isSubscribed ? (
                  <p
                    className="pt4 cursor-pointer min-w71"
                    onClick={() => {
                      setChosenInsight({
                        title: 'INSIGHTS-PAGE.COMMON.TOP-CITIES',
                        type: 'progress-location',
                        data: topCities,
                      });
                    }}
                  >
                    {t('COMMON.VIEW-ALL')}
                  </p>
                ) : (
                  <Subscription
                    type="element"
                    element={<img className="ml-auto" src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
                  />
                )}
              </div>
              <div>
                {locationsLoading && <Loading size="small" />}

                {!locationsLoading &&
                  topCities &&
                  topCities.length > 0 &&
                  topCities.slice(0, 7).map((item, index: number) => (
                    <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                      <div className="d-flex jc-space-between min-h30">
                        <p className="small">
                          <ReactCountryFlag
                            countryCode={item?.countryCode}
                            style={{
                              fontSize: '2em',
                            }}
                          />
                          <span className="pl8">{item?.name}</span>
                        </p>
                        <p className="small text-faded mt-auto mb-auto">{numberFormatter(item?.views, 2)} views</p>
                      </div>
                      <ProgressBar
                        progress={item?.percentageOfTotalViews}
                        tooltip={numberFormatter(item?.percentageOfTotalViews, 2)}
                        title=""
                        label={''}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default YoutubeInsights;

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import LineChartComponent from '@/components/charts/LineChart';
import DateTimeSelector from '@/components/utility/microcomponents/DateTimeSelector';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useLineChartData from '@/hooks/charts/useLineChartData';
import useInstagramAudienceHistoricMetrics from '@/hooks/metrics/useInstagramAudienceHistoricMetrics';
import { MetricsDataModel } from '@/models/Metrics';

const FollowersChart = () => {
  const { t } = useTranslation();

  const [selectedStartDate, setSelectedStartDate] = useState<string>();

  const { instagramAudienceHistoricMetrics } = useInstagramAudienceHistoricMetrics({ fromDate: selectedStartDate });

  const followersDataMap = useMemo(() => {
    if (!instagramAudienceHistoricMetrics) return;
    return [
      {
        name: 'Followers',
        color: 'instagram',
        key: 'value',
        dataMap: new Map(
          instagramAudienceHistoricMetrics?.metrics.map((item) => [
            format(new Date(item.date), 'yyyy-MM-dd'),
            item.followers,
          ])
        ),
      },
    ];
  }, [instagramAudienceHistoricMetrics]);

  const followersChartData = useLineChartData<MetricsDataModel>({
    startDate: selectedStartDate,
    data: followersDataMap,
  });

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <div className="card-inner mb20">
      <div className="d-flex jc-space-between mb8">
        <h4>{t('INSIGHTS-PAGE.INSTAGRAM.INSTAGRAM-FOLLOWERS')}</h4>
        <DateTimeSelector dateOutput={handleDate} defaultRange="This year" />
      </div>
      <h5 className="mb8">
        {numberFormatter(
          followersChartData && followersChartData.data[followersChartData.data.length - 1]?.value
            ? followersChartData.data[followersChartData.data.length - 1]?.value
            : 0
        )}
      </h5>
      <div>
        <LineChartComponent data={followersChartData} />
      </div>
    </div>
  );
};

export default FollowersChart;

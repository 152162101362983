import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { DateTimes } from '@/constants/DateTimes';
import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import { MetricsDataModel } from '@/models/Metrics';

import LineChartComponent from '../../../../charts/LineChart';
import DateTimeSelector from '../../../../utility/microcomponents/DateTimeSelector';

const FollowersChart = () => {
  const { t } = useTranslation();

  const [selectedStartDate, setSelectedStartDate] = useState<string>();

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'twitter',
    fromDate: selectedStartDate,
    toDate: DateTimes.yesterdayDate,
  });

  const followersDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Followers',
        color: 'twitter',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.followers.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const followersChartData = useLineChartData<MetricsDataModel>({
    startDate: selectedStartDate,
    data: followersDataMap,
  });

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <div className="card flex-w50p w100p-lg-down">
      <div className="d-flex jc-space-between mb8">
        <h4>{t('INSIGHTS-PAGE.TWITTER.TWITTER-FOLLOWERS')}</h4>
        <DateTimeSelector dateOutput={handleDate} defaultRange="This year" />
      </div>
      <LineChartComponent data={followersChartData} />
    </div>
  );
};

export default FollowersChart;

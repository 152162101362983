import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import Loading from '@/components/utility/Loading';
import { DateTimes } from '@/constants/DateTimes';
import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import { MetricsDataModel } from '@/models/Metrics';

import LineChartComponent from '../../../../charts/LineChart';
import DateTimeSelector from '../../../../utility/microcomponents/DateTimeSelector';

const LikesChart = () => {
  const { t } = useTranslation();

  const [selectedStartDate, setSelectedStartDate] = useState<string>();

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'tiktok',
    fromDate: selectedStartDate,
    toDate: DateTimes.yesterdayDate,
  });

  const likesDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Likes',
        color: 'tiktok',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.likes.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const likesChartData = useLineChartData<MetricsDataModel>({
    startDate: selectedStartDate,
    data: likesDataMap,
  });

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <div className="card flex-w50p w100p-lg-down">
      <div className="d-flex jc-space-between mb8">
        <h4 className="mb20">{t('INSIGHTS-PAGE.TIKTOK.TIKTOK-LIKES')}</h4>
        <DateTimeSelector dateOutput={handleDate} defaultRange="This year" />
      </div>
      {likesChartData && likesChartData.data.length === 0 && (
        <p className="small">{t('INSIGHTS-PAGE.TIKTOK.NO-DATA')}</p>
      )}
      {likesChartData && likesChartData.data.length > 0 && <LineChartComponent data={likesChartData} />}
      {!likesChartData && (
        <div className="centered-loading mt100 " data-testid="pie-loading">
          <Loading size="small" />
        </div>
      )}
    </div>
  );
};

export default LikesChart;

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import LineChartComponent from '@/components/charts/LineChart';
import DateTimeSelector from '@/components/utility/microcomponents/DateTimeSelector';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useLineChartData from '@/hooks/charts/useLineChartData';
import useInstagramAudienceHistoricMetrics from '@/hooks/metrics/useInstagramAudienceHistoricMetrics';
import { MetricsDataModel } from '@/models/Metrics';

const EngagementChart = () => {
  const { t } = useTranslation();

  const [selectedStartDate, setSelectedStartDate] = useState<string>();

  const { instagramAudienceHistoricMetrics } = useInstagramAudienceHistoricMetrics({ fromDate: selectedStartDate });

  const followersDataMap = useMemo(() => {
    if (!instagramAudienceHistoricMetrics) return;
    return [
      {
        name: 'Engagement',
        color: 'instagram',
        key: 'value',
        dataMap: new Map(
          instagramAudienceHistoricMetrics?.metrics.map((item) => [
            format(new Date(item.date), 'yyyy-MM-dd'),
            item.engagementRate,
          ])
        ),
      },
    ];
  }, [instagramAudienceHistoricMetrics]);

  const engagementChartData = useLineChartData<MetricsDataModel>({
    startDate: selectedStartDate,
    data: followersDataMap,
  });

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <div className="card-inner">
      <div className="d-flex jc-space-between mb8">
        <h4>{t('INSIGHTS-PAGE.INSTAGRAM.ENGAGEMENT')}</h4>
        <DateTimeSelector dateOutput={handleDate} defaultRange="This year" />
      </div>
      <h5 className="mb8">
        {numberFormatter(
          engagementChartData && engagementChartData.data[engagementChartData.data.length - 1]?.value
            ? engagementChartData?.data[engagementChartData.data.length - 1]?.value
            : 0
        )}
        %
      </h5>
      <LineChartComponent data={engagementChartData} suffix="%" />
    </div>
  );
};

export default EngagementChart;
